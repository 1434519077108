import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './QuickPick.css';
import Cookies from 'js-cookie';

const QuickPick = () => {
    const [media, setMedia] = useState({ movies: [], 'tv-shows': [] });
    const [currentList, setCurrentList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentItem, setCurrentItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMediaType, setSelectedMediaType] = useState(null);
    const [watchSoon, setWatchSoon] = useState([]);
    const [watchLater, setWatchLater] = useState([]);
    const username = Cookies.get("username") || 'default';
    const [timerWidth, setTimerWidth] = useState(0);
    const [allDoneClicked, setAllDoneClicked] = useState(false);
    const [quickPickSelected, setQuickPickSelected] = useState(false);
    const location = useLocation();
    const [currentImageIndex, setCurrentImageIndex] = useState(1);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const mediaType = searchParams.get('m');
        if (mediaType === 'tv-shows' || mediaType === 'movies') {
            setSelectedMediaType(mediaType);
        }

        const storedList = localStorage.getItem('sortedWatchList');
        if (storedList) {
            const parsedList = JSON.parse(storedList);
            setCurrentList(parsedList);
        }

        // Set the current image index to a random number between 1 and 8
        setCurrentImageIndex(Math.floor(Math.random() * 8) + 1);

        fetch(`/api/watchlist/${username}`)
            .then(response => response.json())
            .then(data => {
                const movies = data.movies?.map(item => item[1]).sort(() => Math.random() - 0.5) || [];
                const tvShows = data['tv-shows']?.map(item => item[1]).sort(() => Math.random() - 0.5) || [];
                fetchTrendingMedia(movies, tvShows);
            })
            .catch(error => {
                console.error('Error fetching watchlist:', error);
                fetchTrendingMedia([], []);
            });
    }, [location.search, username]);

    const fetchTrendingMedia = (watchlistMovies, watchlistTvShows) => {
        Promise.all([
            fetch('/trending/daily/movies').then(response => response.json()),
            fetch('/trending/daily/tv').then(response => response.json())
        ]).then(([moviesData, tvShowsData]) => {
            const trendingMovies = moviesData.searchResults.results.map(item => ({
                tmdb_id: item.id,
                title: item.title || item.name,
                release_date: item.release_date || item.first_air_date,
                full_poster_path: `https://image.tmdb.org/t/p/w500/${item.poster_path}`,
                overview: item.overview,
                vote_average: item.vote_average,
                vote_count: item.vote_count,
                media_type: 'movie'
            })).sort((a, b) => b.popularity - a.popularity);

            const trendingTvShows = tvShowsData.searchResults.results.map(item => ({
                tmdb_id: item.id,
                title: item.title || item.name,
                release_date: item.release_date || item.first_air_date,
                full_poster_path: `https://image.tmdb.org/t/p/w500/${item.poster_path}`,
                overview: item.overview,
                vote_average: item.vote_average,
                vote_count: item.vote_count,
                media_type: 'tv'
            })).sort((a, b) => b.popularity - a.popularity);

            const uniqueMovies = removeDuplicates([...watchlistMovies, ...trendingMovies]);
            const uniqueTvShows = removeDuplicates([...watchlistTvShows, ...trendingTvShows]);

            setMedia({ movies: uniqueMovies, 'tv-shows': uniqueTvShows });
            setIsLoading(false);
        }).catch(error => {
            console.error('Error fetching trending media:', error);
            setIsLoading(false);
        });
    };

    const removeDuplicates = (items) => {
        const seen = new Set();
        return items.filter(item => {
            const isDuplicate = seen.has(item.tmdb_id);
            seen.add(item.tmdb_id);
            return !isDuplicate;
        });
    };

    const saveToLocalStorage = (finalList) => {
        localStorage.setItem('sortedWatchList', JSON.stringify(finalList));
        localStorage.setItem(`sortedWatchList-${selectedMediaType}`, JSON.stringify(finalList));
    };

    useEffect(() => {
        if (selectedMediaType && quickPickSelected && media[selectedMediaType]?.length > 0) {
            const newList = media[selectedMediaType];
            setCurrentList(newList);
        }
    }, [selectedMediaType, media, quickPickSelected]);

    useEffect(() => {
        if (currentList.length > 0) {
            setCurrentItem(currentList[0]);
            setCurrentIndex(0);
        }
    }, [currentList]);

    useEffect(() => {
        if (currentItem) {
            const intervalId = setInterval(() => {
                setTimerWidth(prevWidth => {
                    if (prevWidth >= 100) {
                        clearInterval(intervalId);
                        handleWatchLater();
                        return 100;
                    }
                    return prevWidth + (100 / 60);
                });
            }, 100);

            return () => {
                clearInterval(intervalId);
                setTimerWidth(0);
            };
        }
    }, [currentItem]);


    const handleWatchSoon = () => {
        setWatchSoon(prev => [...prev, currentItem]);
        moveToNext();
    };

    const handleWatchLater = () => {
        setWatchLater(prev => [...prev, currentItem]);
        moveToNext();
    };

    const moveToNext = () => {
        setCurrentIndex(prev => {
            const nextIndex = prev + 1;
            if (nextIndex < currentList.length) {
                setCurrentItem(currentList[nextIndex]);
            } else {
                setCurrentItem(null);
            }
            return nextIndex;
        });
    };

    const renderFinalList = () => {
        const finalList = sortedFinalList();
        saveToLocalStorage(finalList);
        return (
            <div className="grid-container">
            </div>
        );
    };

    const handleQuickPickSelection = () => {
        // hide the image that has the not-hide class
        const notHideImage = document.querySelector('.not-hide');
        if (notHideImage) {
            notHideImage.style.opacity = .15;
            notHideImage.classList.remove('not-hide');
        }
        setQuickPickSelected(true);
        clearLists();
    };

    const clearLists = () => {
        setWatchSoon([]);
        setWatchLater([]);
        setCurrentItem(null);
        setCurrentIndex(0);
    };

    const sortedFinalList = () => {
        const sortedSoon = [...watchSoon].sort((a, b) => b.vote_average - a.vote_average);
        // const sortedLater = [...watchLater].sort((a, b) => b.vote_average - a.vote_average);
        // const finalList = [...sortedSoon, ...currentList.slice(currentIndex), ...sortedLater];
        return sortedSoon;
    };

    const handleAllDone = () => {
        setWatchLater(prev => [...prev, ...currentList.slice(currentIndex)]);
        setCurrentItem(null);
        setCurrentIndex(currentList.length);
        setAllDoneClicked(true);
        // Go to the watchlist /watchlist-game?m={selectedMediaType} using window.location.href
        window.location.href = `/watchlist-game?m=${selectedMediaType}`;
    };

    return (
        <div className="quick-pick-container" >
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div className="quick-pick-content" >
                    {// QUICK PICK INTRO SECTION STARTS HERE
                    } 
                    {selectedMediaType && !quickPickSelected && (
                        <div style={{ textAlign: "center", padding: "20px", zIndex:1 }}>
                            <h1>Welcome to Quick Pick!</h1>

                            <div style={{ position: "relative", width: "100%", maxWidth: "600px", maxHeight: "800px" }}>
                                <p style={{ fontSize: "30px", color: "white", maxWidth: "800px", margin: "0 auto" }}>
                                    Our fast-paced approach ensures you get the best suggestions without any hassle.
                                </p>
                                <button className="btn btn-primary" onClick={handleQuickPickSelection} style={{ fontSize: "18px", padding: "10px 20px", marginTop: "40px" }}>GET STARTED</button>
                                <br />

                                <p style={{ fontSize: "30px", color: "white", maxWidth: "800px", marginTop: "10px" }}>
                                    Find something to watch tonight, from your watch list or trending movies and TV shows to give you the best suggestions.
                                </p>
                            </div>
                        </div>
                    )}
                    { // QUICK PICK INTRO SECTION ENDS HERE
                    }
                    <span style={{ color:"white", fontSize: "20px", zIndex:1,marginBottom:"10px" }}> Don't think, just pick. </span>
                    {selectedMediaType && quickPickSelected && currentItem && (
                        <>
                            <img src={currentItem.full_poster_path} alt={currentItem.title} style={{  width: "300px", float: "left", marginRight: 10, zIndex:1}} />
                            <div style={{ zIndex: 1, opacity: 1, marginTop:"20px" }}>
                                <div className='btn btn-primary' style={{ marginRight: "15px", fontSize:"15px" }} onClick={handleWatchSoon}><span>Watch Soon</span></div>
                                <button className='btn btn-secondary' onClick={handleWatchLater} style={{ marginLeft: "15px", fontSize:"15px" }}>
                                    Later 
                                    <div className='timer' style={{ width: `${timerWidth}%` }}></div>
                                </button>
                            </div>
                        </>
                    )}
                    {!allDoneClicked && watchSoon.length >= 3 && (
                        <div className="alert alert-success" role="alert" style={{ marginTop: "20px", zIndex:1 }}>
                            {currentList.length - currentIndex > 0
                                ? `Keep going! You have ${currentList.length - currentIndex} items left.`
                                : "That's all for now.."}
                            <button className="btn btn-success" onClick={handleAllDone}>All Done</button>
                        </div>
                    )}                    
                    
                    {!currentItem && currentList.length > 0 && (
                        renderFinalList()
                    )}
                </div>
            )}
        </div>
    );
};

export default QuickPick;

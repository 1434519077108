import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './HomePage.css';
import SearchBar from './SearchBar';
import MediaGrid from './MediaGrid';
import Cookies from 'js-cookie';

const HomePage = () => {
  const [media, setMedia] = useState({ movies: [], 'tv-shows': [] });
  const [visibleMedia, setVisibleMedia] = useState({ movies: [], 'tv-shows': [] });
  const [currentView, setCurrentView] = useState('tv');  // Default to 'tv'
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const username = Cookies.get("username") || 'default';
  const location = useLocation();
  document.title = 'Discover';

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('s');
    if (searchQuery) {
      setSearchTerm(searchQuery);
    }
  }, [location.search]);
  useEffect(() => {
    const storedMoviesList = localStorage.getItem('sortedWatchList-movies');
    const storedTVList = localStorage.getItem('sortedWatchList-tv-shows');

    const parsedMoviesList = storedMoviesList ? JSON.parse(storedMoviesList) : [];
    const parsedTVList = storedTVList ? JSON.parse(storedTVList) : [];

    setMedia({ movies: parsedMoviesList, 'tv-shows': parsedTVList });
    setVisibleMedia({ movies: parsedMoviesList, 'tv-shows': parsedTVList });

    setIsLoading(false);
  }, [username]);

  const handleTabClick = (view) => {
    setCurrentView(view);
  };
  const renderQuickPicks = (type) => {
    const finalList = visibleMedia[type]; // Display items for quick picks
    return (
        <>
            <div style={{fontSize:"20px", color: "lightgray", marginLeft: "15px",paddingBottom:"10px", paddingTop:"10px" }}><a href={`/watchlist-game?m=${type}`} style={{ color: "white" }}>{type.toUpperCase()} WATCHLIST</a></div>
            <div className="grid-container-all">

                {finalList.length > 0 ? (
                    finalList.map((item, index) => (
                        <div className="grid-item" key={index}>
                            <a target='_blank' rel='noreferrer' href={`${item.url}/watch`} target="_blank" rel="noopener noreferrer" style={{ fontSize: "16px", marginLeft: "15px" }}>
                                <img style={{ minWidth: "270px", maxWidth: "270px" }} src={item.full_poster_path} alt={item.title} />
                            </a>
                        </div>
                    ))
                ) : (
                    <div className="quick-pick-card" onClick={() => handleQuickPickSelection(type)}>
                        <button className="btn btn-primary">QUICK PICK {type.replace('-', ' ')?.toUpperCase()}</button>
                    </div>
                )}
            </div>


        </>
    );
};
  return (
    <div>
      <SearchBar initialSearchTerm={searchTerm} />
      <div >
                <div className="" style={{}}>
                    {isLoading ? <p>Loading...</p> : (
                        <>
                            {renderQuickPicks('movies')}
                            {renderQuickPicks('tv-shows')}
                        </>
                    )}
                </div>
            </div>
      <div className="media-type-selector" style={{ paddingTop: "15px" }}>
        <h5 style={{ marginLeft: "17px" }} className={currentView === 'tv' ? 'active-tab' : 'inactive-tab'} onClick={() => handleTabClick('tv')} >
          POPULAR TV
        </h5> <h5 style={{ color: "gray" }}>  : </h5>
        <h5 className={currentView === 'movies' ? 'active-tab' : 'inactive-tab'} onClick={() => handleTabClick('movies')} >
          POPULAR MOVIES
        </h5>
      </div>
      {currentView === 'tv' && (
        <>
          <MediaGrid mediaType="tv" searchTerm={searchTerm} />
        </>
      )}
      {currentView === 'movies' && (
        <>
          <MediaGrid mediaType="movies" searchTerm={searchTerm} />
        </>
      )}
    </div>
  );
};
const handleQuickPickSelection = (type) => {
  window.location.href = `/quick-pick?m=${type}`;
};
export default HomePage;
